import gql from 'graphql-tag';

import {
  ACCOUNT_DELIVERY_LOCATION_FRAGMENT,
  ACCOUNT_FUND_FRAGMENT,
  STORE_FRAGMENT,
  STORE_ACCOUNT_FRAGMENT,
  PRODUCT_FRAGMENT,
  ORDER_FRAGMENT,
} from './fragments';

export const VALIDATE_BARCODE = gql`
  query validateBarcode($barcode: ID) {
    validateBarcode(barcode: $barcode)
  }
`;

// store
export const STORE = gql`
  query store($id: ID!) {
    store(id: $id) {
      ...Store
    }
  }
  ${STORE_FRAGMENT}
`;

export const STORE_WITH_INVENTORY = gql`
  query storeWithInventory($id: ID!) {
    store(id: $id) {
      ...Store
      receivingFund
      subsidyFund
      inventory {
        _id
        owner {
          userId
          firstName
          lastName
        }
        sublocations
      }
    }
  }
  ${STORE_FRAGMENT}
`;

export const UPDATE_STORE = gql`
  mutation UpdateStore($store: StoreInput) {
    updateStore(store: $store) {
      ...Store
      subsidyFund
      receivingFund
    }
  }
  ${STORE_FRAGMENT}
`;

// products
export const PRODUCTS = gql`
  query products(
    $storeId: String!
    $keyword: String
    $availableForSaleOnly: Boolean
    $limit: Int! = 50
    $offset: Int! = 0
  ) {
    products(
      storeId: $storeId
      keyword: $keyword
      availableForSaleOnly: $availableForSaleOnly
      limit: $limit
      offset: $offset
    ) {
      _id
      name
      productNumber
      price
      library {
        ... on ProductLibraryChemical {
          _id
        }
      }
    }
  }
`;

export const STORE_PAGE_QUERY = gql`
  query storePageQuery($storeId: String!, $limit: Int! = 50, $offset: Int! = 0) {
    lowStockProducts(storeId: $storeId, offset: $offset, limit: $limit) {
      _id
      name
      productNumber
      price
      availableStock
      library {
        ... on ProductLibraryChemical {
          _id
        }
        ... on GenericProductLibrary {
          _id
        }
      }
    }
    ordersSummaryByStore(storeId: $storeId)
  }
`;

export const STORE_SUMMARY_PAGE = gql`
  query lowStockProductsStockCount($storeId: String!) {
    lowStockProductsStockCount(storeId: $storeId)
    ordersSummaryByStore(storeId: $storeId)
  }
`;

export const PRODUCTS_FOR_SHOPPING = gql`
  query products(
    $storeId: String!
    $keyword: String
    $availableForSaleOnly: Boolean = true
    $limit: Int! = 50
    $offset: Int! = 0
  ) {
    products(
      storeId: $storeId
      keyword: $keyword
      availableForSaleOnly: $availableForSaleOnly
      limit: $limit
      offset: $offset
    ) {
      _id
      name
      description
      catalog
      productNumber
      price
      details
      images
      library {
        ... on ProductLibraryChemical {
          _id
        }
        ... on GenericProductLibrary {
          _id
        }
      }
    }
  }
`;

export const PRODUCT = gql`
  query product($storeId: String!, $id: ID!) {
    product(storeId: $storeId, id: $id) {
      ...Product
      barcodeRequired
      subsidy
      lowStockThreshold
      library {
        ... on ProductLibraryChemical {
          _id
          name
          cas
          physicalState
        }
        ... on GenericProductLibrary {
          _id
          name
          description
        }
      }
      stock {
        _id
        barcode
        location {
          buildingId
          buildingName
          roomId
          roomNumber
          sublocationId
          sublocationName
        }
        createdDate
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const PRODUCT_FOR_SHOPPING = gql`
  query product($storeId: String!, $id: ID!) {
    product(storeId: $storeId, id: $id) {
      ...Product
      library {
        ... on ProductLibraryChemical {
          _id
          name
          cas
          physicalState
        }
        ... on GenericProductLibrary {
          _id
          name
          description
        }
      }
      subsidy
      lowStockThreshold
      availableStock
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const ITEM_BY_BARCODE_OR_SKU = gql`
  query itemByBarcodeSku($storeId: ID!, $inventoryId: ID!, $barcode: String!) {
    inventoryItemByBarcode(inventoryId: $inventoryId, barcode: $barcode) {
      _id
      barcode
      product {
        _id
        name
        description
        price
        fee
      }
    }
    productBySku(storeId: $storeId, sku: $barcode) {
      _id
      name
      barcodeRequired
      availableStock
      description
      sku
      catalog
      price
      fee
    }
    inventoryItemWithoutBarcode(storeId: $storeId, sku: $barcode) {
      _id
      product {
        _id
        name
        description
        price
        fee
        sku
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($storeId: String!, $product: ProductInput!) {
    createProduct(storeId: $storeId, product: $product) {
      ...Product
      subsidy
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($storeId: String!, $product: ProductInput!) {
    updateProduct(storeId: $storeId, product: $product) {
      ...Product
      lowStockThreshold
      subsidy
      library {
        ... on ProductLibraryChemical {
          _id
          name
          cas
          physicalState
        }
        ... on GenericProductLibrary {
          _id
          name
          description
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const ADD_PRODUCT_IMAGE = gql`
  mutation addProductImage($productId: ID!, $url: String!, $fileName: String!) {
    addProductImage(productId: $productId, url: $url, fileName: $fileName) {
      _id
      images
    }
  }
`;

export const REMOVE_PRODUCT_IMAGE = gql`
  mutation removeProductImage($productId: ID!, $url: String!) {
    removeProductImage(productId: $productId, url: $url) {
      _id
      images
    }
  }
`;

export const ADD_PRODUCT_ITEMS = gql`
  mutation addProductItems(
    $productId: ID!
    $storeId: String!
    $sublocationId: String!
    $barcodes: [String]
    $quantity: Int!
  ) {
    addProductItems(
      productId: $productId
      storeId: $storeId
      sublocationId: $sublocationId
      barcodes: $barcodes
      quantity: $quantity
    ) {
      _id
      stock {
        _id
        barcode
        location {
          roomId
          sublocationId
          buildingName
          roomNumber
          sublocationName
        }
      }
    }
  }
`;

export const DELETE_PRODUCT_ITEMS = gql`
  mutation deleteProductItems($productId: ID!, $storeId: String!, $ids: [String]) {
    deleteProductItems(productId: $productId, storeId: $storeId, ids: $ids) {
      _id
      stock {
        _id
        barcode
        location {
          roomId
          sublocationId
          buildingName
          roomNumber
          sublocationName
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT_ITEMS = gql`
  mutation updateProductItems($productId: ID!, $storeId: String!, $items: [StockInput]) {
    updateProductItems(productId: $productId, storeId: $storeId, items: $items) {
      _id
      stock {
        _id
        barcode
        location {
          roomId
          sublocationId
          buildingName
          roomNumber
          sublocationName
        }
      }
    }
  }
`;

export const PRODUCT_IMAGE_PRESIGNED_URL = gql`
  query productImagePresignedUrl($storeId: String!, $fileName: String!) {
    productImagePresignedUrl(storeId: $storeId, fileName: $fileName)
  }
`;

export const UPSERT_SUBLOCATION = gql`
  mutation UpsertSublocation($sublocation: SublocationInput!) {
    upsertSublocation(sublocation: $sublocation) {
      _id
      name
    }
  }
`;

export const REMOVE_SUBLOCATION = gql`
  mutation RemoveSublocation($fromLocation: SublocationInput!) {
    removeSublocation(fromLocation: $fromLocation)
  }
`;

// order
export const ORDER_SUMMARY = gql`
  query OrdersSummaryByStore($storeId: String!) {
    ordersSummaryByStore(storeId: $storeId)
  }
`;

export const VALIDATE_SHOPPING_CART = gql`
  query validateShoppingCart($storeId: String!, $accountId: String, $fundId: String, $items: [CartItemInput]) {
    validateShoppingCart(storeId: $storeId, accountId: $accountId, fundId: $fundId, items: $items) {
      account {
        _id
        name
      }
      fund {
        id
        name
        value
      }
      isFundValid
      items {
        _id
        name
        description
        price
        originalPrice
        hasPriceChanged
        fee
        quantity
        quantityAvailable
        availableForSale
        taxExempt
        salesTax
        catalog
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query getOrders(
    $storeId: String!
    $createdByUserId: String
    $orderId: String
    $status: [String]
    $fromDate: String
    $toDate: String
    $myOrders: Boolean
  ) {
    orders(
      storeId: $storeId
      createdByUserId: $createdByUserId
      orderId: $orderId
      status: $status
      fromDate: $fromDate
      toDate: $toDate
      myOrders: $myOrders
    ) {
      _id
      status
      account {
        administeredBy {
          id
          name
        }
      }
      items {
        quantity
      }
      receivingType
    }
  }
`;

export const GET_ORDERS_FOR_PACKING_SLIP = gql`
  query ordersForPackingSlip($storeId: String!, $orderIds: [String]!) {
    ordersForPackingSlip(storeId: $storeId, orderIds: $orderIds) {
      _id
      account {
        administeredBy {
          id
          name
        }
      }
      fund {
        name
      }
      deliveryLocation {
        buildingName
        roomNumber
        sublocationName
      }
      items {
        _id
        product {
          name
          description
        }
        quantity
      }
      receivingType
      createdDate
      createdBy {
        userId
        firstName
        lastName
        email
      }
    }
  }
`;

export const FIND_VALID_REFUND_ITEMS = gql`
  query findValidRefundItems($id: String!, $campusCode: String!) {
    findValidRefundItems(id: $id, campusCode: $campusCode) {
      product {
        _id
        name
        library {
          _id
          catalog
        }
      }
      inventoryItems {
        _id
        barcode
      }
      quantity
      isRefundable
    }
  }
`;

export const ORDER = gql`
  query order($id: String!) {
    order(id: $id) {
      ...Order
      account {
        administeredBy {
          id
          name
        }
      }
      fund {
        name
        value
      }
      deliveryLocation {
        buildingName
        roomNumber
        sublocationName
      }
      items {
        _id
        quantity
        salesTax
        product {
          name
          description
          price
          fee
          taxExempt
          sku
        }
        productLocations {
          roomNumber
          sublocationName
        }
      }
      history {
        status
        date
      }
      refunds {
        createdDate
        createdBy {
          userId
          firstName
          lastName
          email
        }
        refundTotal
        refundedItems {
          productId
          quantity
          description
          productName
          price
          reasons
        }
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const SUBMIT_ORDER = gql`
  mutation submitOrder($storeId: String!, $order: OrderInput) {
    submitOrder(storeId: $storeId, order: $order) {
      _id
      status
      account {
        administeredBy {
          id
          name
        }
      }
      items {
        _id
        quantity
      }
      createdDate
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($orderId: String!) {
    cancelOrder(orderId: $orderId) {
      _id
      status
      history {
        status
        date
      }
    }
  }
`;

export const FULFILL_ORDER = gql`
  mutation fulfillOrder($storeId: String!, $orderId: String!, $items: [JSON]!, $notes: String) {
    fulfillOrder(storeId: $storeId, orderId: $orderId, items: $items, notes: $notes) {
      _id
      status
      history {
        status
        date
      }
    }
  }
`;

export const COMPLETE_ORDER = gql`
  mutation completeOrder($storeId: String!, $orderId: String!, $status: String!) {
    completeOrder(storeId: $storeId, orderId: $orderId, status: $status) {
      ...Order
      history {
        status
        date
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const SUBMIT_REFUND = gql`
  mutation refundOrderItems($orderId: String!, $storeId: String!, $refundItems: [RefundItemInput]!) {
    refundOrderItems(orderId: $orderId, storeId: $storeId, refundItems: $refundItems) {
      ...Order
      account {
        administeredBy {
          id
          name
        }
      }
      fund {
        name
        value
      }
      deliveryLocation {
        buildingName
        roomNumber
        sublocationName
      }
      items {
        _id
        quantity
        salesTax
        product {
          name
          description
          price
          fee
          taxExempt
        }
        productLocations {
          roomNumber
          sublocationName
        }
      }
      history {
        status
        date
      }
      refunds {
        createdDate
        createdBy {
          userId
          firstName
          lastName
          email
        }
        refundTotal
        refundedItems {
          productId
          quantity
          description
          productName
          price
          reasons
        }
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

// transaction
export const TRANSACTIONS = gql`
  query transactions(
    $storeId: String!
    $keyword: String
    $startDate: String!
    $endDate: String!
    $cursor: String! = ""
  ) {
    transactions(storeId: $storeId, keyword: $keyword, startDate: $startDate, endDate: $endDate, cursor: $cursor)
  }
`;

export const TRANSACTION_EXCEL_FILE = gql`
  query transactionExcelFile($storeId: String!, $keyword: String, $startDate: String!, $endDate: String!) {
    transactionExcelFile(storeId: $storeId, keyword: $keyword, startDate: $startDate, endDate: $endDate)
  }
`;

export const TRANSACTION_JOURNAL_FILE = gql`
  query transactionJournalFile($storeId: String!, $keyword: String, $startDate: String, $endDate: String) {
    transactionJournalFile(storeId: $storeId, keyword: $keyword, startDate: $startDate, endDate: $endDate)
  }
`;

// inventory item
export const INVENTORY_ITEM_BY_BARCODE = gql`
  query InventoryItemByBarcode($inventoryId: ID!, $barcode: String!) {
    inventoryItemByBarcode(inventoryId: $inventoryId, barcode: $barcode) {
      _id
      barcode
      product {
        _id
      }
    }
  }
`;

export const INVENTORY_ITEM_COUNT_BY_SUBLOCATION = gql`
  query inventoryItemBySublocation($inventoryId: ID!, $sublocationIds: [String]) {
    inventoryItemBySublocation(inventoryId: $inventoryId, sublocationIds: $sublocationIds)
  }
`;

// accounts
export const STORE_ACCOUNT = gql`
  query storeAccount($id: String!, $storeId: String) {
    storeAccount(id: $id, storeId: $storeId) {
      ...StoreAccount
      administeredBy {
        id
        name
        label
        members
      }
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const FIND_ACCOUNTS_FOR_USER = gql`
  query findAccountsForUser($storeId: String!, $administeredByLabel: String!, $filterEmpty: Boolean) {
    findAccountsForUser(storeId: $storeId, administeredByLabel: $administeredByLabel, filterEmpty: $filterEmpty) {
      id
      label
      name
      owner {
        userId
        firstName
        lastName
        email
      }
      account {
        ...StoreAccount
        administeredBy {
          id
          name
          label
        }
      }
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const LIST_STORE_ACCOUNTS = gql`
  query listStoreAccounts($storeId: String!, $campusCode: String!, $keyword: String, $offset: Int, $displayCount: Int) {
    listStoreAccounts(
      storeId: $storeId
      campusCode: $campusCode
      keyword: $keyword
      offset: $offset
      displayCount: $displayCount
    ) {
      totalHits
      userAccountSearchList {
        id
        label
        name
        owner {
          userId
          firstName
          lastName
          email
        }
        account {
          ...StoreAccount
          administeredBy {
            id
            label
          }
        }
      }
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const ACCOUNTS_BY_STORE = gql`
  query findAccountsForStore($storeId: String!, $administeredByLabel: String!) {
    findAccountsForStore(storeId: $storeId, administeredByLabel: $administeredByLabel) {
      _id
      administeredBy {
        id
        name
        members
      }
      funds {
        ...AccountFund
      }
      deliveryLocations {
        ...AccountDeliveryLocation
      }
    }
  }
  ${ACCOUNT_FUND_FRAGMENT}
  ${ACCOUNT_DELIVERY_LOCATION_FRAGMENT}
`;

export const CREATE_STORE_ACCOUNT = gql`
  mutation createStoreAccount(
    $storeId: String!
    $administeredById: String!
    $administeredByLabel: String!
    $administeredByName: String!
    $administeredByOwnerLastName: String!
  ) {
    createStoreAccount(
      storeId: $storeId
      administeredById: $administeredById
      administeredByLabel: $administeredByLabel
      administeredByName: $administeredByName
      administeredByOwnerLastName: $administeredByOwnerLastName
    ) {
      ...StoreAccount
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;
export const ADD_FUND = gql`
  mutation addStoreAccountFund($id: String!, $fund: StoreAccountFundInput!, $storeId: String) {
    addStoreAccountFund(id: $id, fund: $fund, storeId: $storeId) {
      ...StoreAccount
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const UPDATE_FUND = gql`
  mutation updateStoreAccountFund($id: String!, $fund: StoreAccountFundInput!, $storeId: String) {
    updateStoreAccountFund(id: $id, fund: $fund, storeId: $storeId) {
      ...StoreAccount
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const REMOVE_FUND = gql`
  mutation removeStoreAccountFund($id: String!, $fundId: String!, $storeId: String) {
    removeStoreAccountFund(id: $id, fundId: $fundId, storeId: $storeId) {
      ...StoreAccount
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const VALIDATE_FUND = gql`
  query validateFund($type: String!, $value: JSON!, $validator: String!) {
    validateFund(type: $type, value: $value, validator: $validator) {
      valid
      error
    }
  }
`;

export const ADD_DELIVERY_LOCATION = gql`
  mutation addStoreAccountDeliveryLocation(
    $id: String!
    $deliveryLocation: StoreAccountDeliveryLocationInput!
    $storeId: String
  ) {
    addStoreAccountDeliveryLocation(id: $id, deliveryLocation: $deliveryLocation, storeId: $storeId) {
      ...StoreAccount
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const UPDATE_DELIVERY_LOCATION = gql`
  mutation updateStoreAccountDeliveryLocation(
    $id: String!
    $deliveryLocation: StoreAccountDeliveryLocationInput!
    $storeId: String
  ) {
    updateStoreAccountDeliveryLocation(id: $id, deliveryLocation: $deliveryLocation, storeId: $storeId) {
      ...StoreAccount
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const REMOVE_DELIVERY_LOCATION = gql`
  mutation removeStoreAccountDeliveryLocation($id: String!, $deliveryLocationId: String!, $storeId: String) {
    removeStoreAccountDeliveryLocation(id: $id, deliveryLocationId: $deliveryLocationId, storeId: $storeId) {
      ...StoreAccount
    }
  }
  ${STORE_ACCOUNT_FRAGMENT}
`;

export const FIND_SUBLOCATION_BY_INVENTORY = gql`
  query findSublocationByInventory($inventoryId: String!) {
    findSublocationByInventory(inventoryId: $inventoryId) {
      _id
      inventoryId
      buildingId
      buildingName
      floorId
      floorName
      roomId
      roomNumber
      name
    }
  }
`;

export const GET_PURCHASING_CODE = gql`
  query {
    purchasingCode
  }
`;

export const REFRESH_PURCHASING_CODE = gql`
  mutation {
    refreshPurchasingCode
  }
`;

export const GET_USER_FROM_PURCHASING_CODE = gql`
  query userFromPurchasingCode($token: String!) {
    userFromPurchasingCode(token: $token) {
      userId
      firstName
      lastName
      email
      tenantCode
      campusCode
  }
}`

export const SUBMIT_ORDER_STORE_CHECKOUT = gql`
  mutation submitOrderStoreCheckout(
    $token: String
    $storeId: String!
    $order: OrderInput
    $shopper: PersonReferenceInput
    $notes: String
  ) {
    submitOrderStoreCheckout(token: $token, storeId: $storeId, order: $order, shopper: $shopper, notes: $notes) {
      _id
      status
      createdDate
    }
  }
`;

export const REMOVE_PRODUCT = gql`
  mutation removeProduct($productId: ID!) {
    removeProduct(productId: $productId) {
      _id
    }
  }
`;
