import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { ObjectIdSchema } from '@rss/common';
import { CATALOG_TYPE, Store } from '@risk-and-safety/library';
import { CONTAINER_TYPE, UNITS } from '@risk-and-safety/chemical';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { CREATE_PRODUCT, UPDATE_PRODUCT } from '../graphql/query';
import ChemicalSearch from '../../chemical/components/ChemicalSearch';
import { ButtonProgress, LibrarySearch } from '../../components';

// TODO: pull from Project model
const schema = yup.object({
  _id: new ObjectIdSchema(),
  storeId: new ObjectIdSchema(),
  productNumber: yup.string().trim().required('Product number is required'),
  name: yup.string().trim().required(),
  description: yup.string().default(''),
  catalog: yup.string().required(),
  price: yup.number().min(0),
  fee: yup.number().min(0),
  subsidy: yup.number().min(0),
  lowStockThreshold: yup.number(),
  availableForSale: yup.bool().default(true),
  taxExempt: yup.bool().default(false),
  barcodeRequired: yup.bool().default(true),
  library: yup.object().when('catalog', {
    is: CATALOG_TYPE.SERVICES,
    then: yup.object().defined(),
    otherwise: yup.object({
      _id: new ObjectIdSchema().required('Library reference is required'),
    }),
  }),
  details: yup.object().when('catalog', {
    is: CATALOG_TYPE.CHEMICAL,
    then: yup.object({
      size: yup
        .number()
        .typeError('Container size must be a number')
        .positive('Container size must be greater than 0')
        .required(),
      units: yup.string().required('Container units is required'),
      type: yup.string().required('Container type is required'),
    }),
    otherwise: yup.object().nullable(),
  }),
});

function ProductDetailEdit({ product, store }) {
  const history = useHistory();
  const currentPath = window.location.pathname;
  const [showSearch, setShowSearch] = useState(false);

  const navigateBack = () => history.push(`/store/${store._id}/product/${product._id}/detail`);

  const [createProduct, { loading: createLoading }] = useMutation(CREATE_PRODUCT, { onCompleted: navigateBack });
  const [updateProduct, { loading: updateLoading }] = useMutation(UPDATE_PRODUCT, { onCompleted: navigateBack });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      _id: product?._id,
      name: product?.name || '',
      description: product?.description || '',
      catalog: product?.catalog || CATALOG_TYPE.CHEMICAL,
      productNumber: product?.productNumber || '',
      availableForSale: typeof product?.availableForSale === 'boolean' ? product.availableForSale : true,
      taxExempt: product?.taxExempt || false,
      price: product?.price || 0,
      fee: product?.fee || 0,
      subsidy: product?.subsidy || 0,
      lowStockThreshold: product?.lowStockThreshold || 0,
      barcodeRequired: typeof product?.barcodeRequired === 'boolean' ? product.barcodeRequired : false,
      library: {
        _id: product?.library?._id,
        catalog: product?.catalog || CATALOG_TYPE.CHEMICAL,
        cas: product?.library?.cas,
        name: product?.library?.name,
        physicalState: product?.library?.physicalState,
        description: product?.library?.description,
      },
      details: {
        size: product?.details?.size || '1',
        units: product?.details?.units || 'g',
        type: product?.details?.type || 'GLASS_BOTTLE',
        physicalState: product?.details?.physicalState || product?.library?.physicalState,
        density: product?.details?.density || product?.library?.density,
      },
    },
    resolver: yupResolver(schema),
  });

  const isService = watch('catalog') == CATALOG_TYPE.SERVICES;
  const isChemical = watch('catalog') == CATALOG_TYPE.CHEMICAL;
  const isProductAdd = /add/.test(currentPath);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit((value) =>
        product.isNew
          ? createProduct({ variables: { storeId: store._id, product: value } })
          : updateProduct({ variables: { storeId: store._id, product: value } }),
      )}
      className="pb-40">
      <div className="absolute left-48 top-136 flex h-64 items-center">
        <IconButton
          aria-label="back"
          onClick={() =>
            history.push(
              product.isNew ? `/store/${store._id}/product` : `/store/${store._id}/product/${product._id}/detail`,
            )
          }>
          <Icon>arrow_back</Icon>
        </IconButton>
      </div>
      <div className="absolute right-48 top-136 flex h-64 items-center">
        <ButtonProgress
          id="product-submit"
          loading={createLoading || updateLoading}
          type="submit"
          variant="contained"
          color="primary">
          Save
        </ButtonProgress>
      </div>
      <Controller
        name="catalog"
        control={control}
        render={({ field }) => (
          <FormControl className="mb-16 mt-8 w-full" variant="outlined" required error={errors?.catalog}>
            <InputLabel htmlFor="category-label-placeholder">Catalog</InputLabel>
            <Select
              {...field}
              input={
                <OutlinedInput labelWidth={'category'.length * 7} name="category" id="category-label-placeholder" />
              }
              onChange={({ target: { value } }) => {
                setValue('catalog', value);
                setValue('library', { catalog: value });
                setValue('details', {});
              }}>
              {store.catalogs.map((catalog) => (
                <MenuItem key={catalog.value} value={catalog.value}>
                  {catalog.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <div className="mb-16">
        {!isService && (
          <div role="list" aria-roledescription="list" className="mb-16 mt-16">
            <Controller
              name="library"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {showSearch || !value._id ? (
                    <>
                      {watch('catalog') === CATALOG_TYPE.CHEMICAL ? (
                        <label role="listitem">
                          <ChemicalSearch
                            role="search"
                            id="chemical-search"
                            onSelect={(chem) => {
                              const state = chem.formNormalize || chem.form;
                              onChange({
                                _id: chem._id,
                                catalog: CATALOG_TYPE.CHEMICAL,
                                cas: chem.identifiers?.cas,
                                name: chem.name,
                                physicalState: chem.formNormalize || chem.form,
                              });
                              setValue('details.physicalState', state);
                              setValue('details.density', chem.density);
                              setShowSearch(false);
                            }}
                          />
                        </label>
                      ) : (
                        <label role="listitem">
                          <LibrarySearch
                            id="library-search"
                            catalog={watch('catalog')}
                            onSelect={(item) =>
                              onChange({
                                _id: item._id,
                                catalog: item.catalog,
                                name: item.name,
                                description: item.description,
                              })
                            }
                          />
                        </label>
                      )}

                      {errors?.library?._id && (
                        <FormHelperText className="text-red-500">{errors?.library?._id?.message}</FormHelperText>
                      )}
                    </>
                  ) : (
                    <ListItem role="listitem" className="mb-20 mt-5 border-1" divider ContainerComponent="div">
                      {watch('catalog') === CATALOG_TYPE.CHEMICAL ? (
                        <ListItemText
                          primary={value.name}
                          secondary={`Physical State: ${value.physicalState || 'N/A'} CAS: ${value.cas || 'N/A'}`}
                        />
                      ) : (
                        <ListItemText primary={value.name} secondary={value.description} />
                      )}
                      <ListItemSecondaryAction>
                        <IconButton aria-label="delete" onClick={() => setShowSearch(true)}>
                          <Icon>edit</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </>
              )}
            />
          </div>
        )}

        {watch('library._id') && watch('catalog') === CATALOG_TYPE.CHEMICAL && (
          <div className="my-8 grid grid-cols-3 gap-6">
            <Controller
              name="details.size"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="container-size"
                  label="Container Size"
                  type="number"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    'aria-labelledby': 'container-size',
                    inputProps: { min: 0 },
                  }}
                  error={!!errors.details?.size}
                  helperText={errors?.details?.size?.message}
                />
              )}
            />

            <Controller
              name="details.units"
              control={control}
              render={({ field }) => (
                <FormControl variant="outlined" required>
                  <InputLabel htmlFor="units-label-placeholder">Units</InputLabel>
                  <Select
                    {...field}
                    input={<OutlinedInput labelWidth={'units'.length * 7} name="units" id="units-label-placeholder" />}>
                    {UNITS.map(({ value }) => (
                      <MenuItem value={value} key={`unit_${value}`}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              name="details.type"
              control={control}
              render={({ field }) => (
                <FormControl variant="outlined" required>
                  <InputLabel htmlFor="type-label-placeholder-container">Container Type</InputLabel>
                  <Select
                    {...field}
                    input={
                      <OutlinedInput
                        labelWidth={'Container Type'.length * 7}
                        name="type"
                        id="type-label-placeholder-container"
                      />
                    }>
                    {CONTAINER_TYPE.map(({ label, value }) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>
        )}
      </div>

      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="edit-product-name"
            className="mb-16 mt-8"
            error={!!errors.name}
            helperText={errors?.name?.message}
            label="Name"
            variant="outlined"
            fullWidth
            required
          />
        )}
      />

      <Controller
        name="productNumber"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="edit-product-number"
            className="mb-16 mt-8"
            error={!!errors.productNumber}
            helperText={errors?.productNumber?.message}
            label="Product Number"
            variant="outlined"
            fullWidth
            required
          />
        )}
      />

      <Controller
        name="barcodeRequired"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Typography variant="subtitle1" className="ml-8 text-sm">
            Barcode Required?
            <Tooltip title="This field cannot be changed after supply creation">
              <span>
                <Checkbox
                  value="barcodeRequired"
                  disabled={isService || !isProductAdd}
                  checked={value && !isService}
                  onChange={(ev) => {
                    onChange(ev.target.checked);
                  }}
                  color="primary"
                />
              </span>
            </Tooltip>
          </Typography>
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            id="description"
            {...field}
            className="mb-16 mt-8"
            error={!!errors.description}
            helperText={errors?.description?.message}
            label="Description"
            multiline
            rows={5}
            variant="outlined"
            inputProps={{ 'aria-label': 'description' }}
            fullWidth
          />
        )}
      />
      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="edit-product-price"
            className="mb-8 mt-8"
            error={!!errors.price}
            helperText={errors?.price?.message}
            label="Price"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: { min: 0 },
              ...(store.catalogs.find((c) => c.value === product.catalog)?.markupRate > 0
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography color="textSecondary">
                          {`${parseFloat(field.value)} + ${parseFloat(
                            parseFloat(field.value) *
                              store.catalogs.find((c) => c.value === product.catalog)?.markupRate,
                          ).toFixed(2)} (markup rate)`}
                        </Typography>
                      </InputAdornment>
                    ),
                  }
                : {}),
            }}
            type="number"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="fee"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="fee"
            className="mb-8 mt-8"
            error={!!errors.fee}
            helperText={errors?.fee?.message}
            label="Fee"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: { min: 0 },
              'aria-label': 'fee',
            }}
            type="number"
            variant="outlined"
            fullWidth
          />
        )}
      />
      {store.subsidyFund?.type && (
        <Controller
          name="subsidy"
          control={control}
          render={({ field }) => (
            <TextField
              id="subsidy"
              {...field}
              className="mb-8 mt-8"
              error={!!errors.subsidy}
              helperText={errors?.subsidy?.message}
              label="Subsidy"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: { min: 0 },
                'aria-label': 'subsidy',
              }}
              type="number"
              variant="outlined"
              fullWidth
            />
          )}
        />
      )}

      {!isService && (
        <Controller
          name="lowStockThreshold"
          control={control}
          render={({ field }) => (
            <TextField
              id="low-stock-threshold"
              {...field}
              className="mb-8 mt-8"
              error={!!errors.lowStockThreshold}
              helperText={errors?.lowStockThreshold?.message}
              label="Low stock threshold"
              InputProps={{
                inputProps: { min: 0 },
                'aria-label': 'low-stock-threshold',
              }}
              type="number"
              variant="outlined"
              fullWidth
            />
          )}
        />
      )}

      <div>
        <Controller
          name="taxExempt"
          control={control}
          render={({ field }) => (
            <FormControl className="mb-8 mt-8 w-4/12" variant="outlined" required>
              <InputLabel shrink htmlFor="type-label-placeholder-tax">
                Tax Exempt
              </InputLabel>
              <Select
                {...field}
                input={
                  <OutlinedInput labelWidth={'Container Type'.length * 7} name="type" id="type-label-placeholder-tax" />
                }>
                <MenuItem value={false} key={0}>
                  Not Exempt
                </MenuItem>
                <MenuItem value key={1}>
                  Exempt
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>

      <Controller
        name="availableForSale"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={<Switch checked={value} onChange={(ev) => onChange(ev.target.checked)} color="primary" />}
            label="Available for Sale"
            className="mr-36"
          />
        )}
      />
    </form>
  );
}

ProductDetailEdit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired, // replace with product shape after model upload
  store: PropTypes.shape(Store).isRequired,
};

export default ProductDetailEdit;
