import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import config from '../../config';
import { useSecurity } from '../../hooks';
import useIdClaim from '../../hooks/useIdClaim';
import useProfile from '../../hooks/useProfile';
import useTenants from '../../hooks/useTenants';
import { getImpersonateId, hasImpersonateAccess } from '../../shared/helper';

export const KIWI_NAV_STATE = {
  DONE: 'done',
  FAILED: 'failed',
  IDLE: 'idle',
  LOADING: 'loading',
};

export function KiwiNavbar() {
  const history = useHistory();
  const webComponentRef = useRef();
  const [status, setStatus] = React.useState(KIWI_NAV_STATE.IDLE);

  const { profile, loading } = useProfile();
  const tenants = useTenants();
  const impersonateId = getImpersonateId();
  const idClaim = useIdClaim();

  const { browserSecurity } = useSecurity();

  const webComponentURL = config.RSS_WC_URL.includes('localhost')
    ? `${config.RSS_WC_URL}/rss-nav-bar.mjs`
    : `${config.RSS_WC_URL}/core/rss-nav-bar.mjs`;

  const setProps = useCallback(() => {
    if (webComponentRef.current) {
      const campus = tenants.getCampus(profile?.campusCode) || { campusCode: '', name: '', uri: '' };
      webComponentRef.current.props = {
        user: {
          isSpoofed: Boolean(impersonateId),
          id: profile?.userId || '',
          firstName: profile?.firstName || '',
          lastName: profile?.lastName || '',
          email: profile?.email || '',
          campus: {
            code: campus?.campusCode,
            name: campus?.displayName || campus?.shortName || campus?.name,
            uri: campus?.uri,
          },
          rss: hasImpersonateAccess(idClaim),
        },
        logout: () => browserSecurity.logout('/'),
        onRouteChange: (route) => {
          // corresponding navbar dropdown url routing logic is at
          // https://github.com/risk-and-safety/core/blob/trunk/packages/core-react/src/kiwi/layout/navbar/ProgramDropdown.tsx#L14C25-L14C25
          if (route.match(/^\/(assessment|fittest|inventory|poc|program|workstrong)/)) {
            window.location.href = route;
            return;
          }

          history.push(route);
        },
      };
    }
  }, [profile, idClaim]);

  useEffect(() => {
    async function load() {
      setStatus(KIWI_NAV_STATE.LOADING);

      try {
        await import(/* webpackIgnore: true */ webComponentURL);
        const newStatus = KIWI_NAV_STATE.DONE;
        setStatus(newStatus);
      } catch (err) {
        const newStatus = KIWI_NAV_STATE.FAILED;
        setStatus(newStatus);
      }
    }

    if (status === KIWI_NAV_STATE.IDLE) {
      load();
    }

    setProps();
  }, [status]);

  if (status !== KIWI_NAV_STATE.DONE || loading) {
    return null;
  }

  return (
    <rss-nav-bar
      ref={webComponentRef}
      ld-client-id={config.LD_CLIENT_ID}
      branding-url={config.BRANDING_GRAPH_URL}
      relationship-url={config.RELATIONSHIP_GRAPH_URL}
    />
  );
}
