import { useMutation, useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageSimple from '@fuse/core//FusePageSimple';
import { Button, Icon, Step, StepButton, Stepper, Typography } from '@material-ui/core';
import { ORDER_STATUS, ORDER_RECEIVING } from '@risk-and-safety/library';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useParams } from 'react-router-dom';

import { ReadOnlyClient } from '../../services/apollo';
import { DialogTemplate, Header } from '../../components';
import { ErrorMessage } from '../../components/errors';
import { useToast } from '../../hooks';
import { CheckoutConfirmation, PurchasingCodeCheckout, ScanCheckoutItems } from '../components';
import {
  BARCODE_SCAN_ERROR,
  generateOrderId,
  SCAN_CHECKOUT_STEPS,
  checkForValidScannedItem,
} from '../helper/order.helper';
import { ACCOUNTS_BY_USER, ITEM_BY_BARCODE_OR_SKU, STORE, SUBMIT_ORDER_SELF_CHECKOUT } from '../graphql/public-query';

const SelfCheckoutPage = () => {
  const { storeId } = useParams();
  const interval = useRef(null);

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [items, setItems] = useState([]);
  const [invalidItems, setInvalidItems] = useState([]);
  const [barcode, setBarcode] = useState(null);
  const [checkoutToken, setCheckoutToken] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [openIdleTimeoutDialog, setOpenIdleTimeoutDialog] = useState(false);
  const [openErrorDialog, setErrorDialog] = useState(false);
  const [timer, setTimer] = useState(30);
  const [orderId, setOrderId] = useState();
  const { showError } = useToast();

  useEffect(() => setOrderId(generateOrderId()), []);

  const idleTimer = useIdleTimer({
    timeout: 60000 * 3 - 30000, // 3 minutes of idle time and warning before 30 seconds
    onIdle: () => setIdleTimeOutCounter(),
  });

  useEffect(() => {
    if (items.length === 0) {
      idleTimer.pause();
    } else {
      idleTimer.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const { data, loading, error } = useQuery(STORE, {
    variables: { id: storeId },
    client: ReadOnlyClient,
  });

  const { data: readOnlyItemData, loading: readOnlyItemLoading } = useQuery(ITEM_BY_BARCODE_OR_SKU, {
    variables: { barcode, inventoryId: data?.store?.inventoryId, storeId },
    skip: !barcode,
    client: ReadOnlyClient,
    fetchPolicy: 'network-only',
    onError: (err) => {
      setInvalidItems([...new Set([...invalidItems, { _id: barcode, barcode, error: BARCODE_SCAN_ERROR.NOT_FOUND }])]);
    },
  });

  const { loading: tokenLoading } = useQuery(ACCOUNTS_BY_USER, {
    variables: { storeId, administeredByLabel: 'INVENTORY', token: checkoutToken },
    client: ReadOnlyClient,
    fetchPolicy: 'network-only',
    skip: !checkoutToken,
    onCompleted: ({ findAccountsForUser = [] }) => {
      if (findAccountsForUser.some((a) => a.funds?.length && a.deliveryLocations?.length)) {
        setInvalidItems([]);
        setAccounts(findAccountsForUser.map((ac) => ({ ...ac, name: ac.administeredBy.name })));
      } else {
        setInvalidItems([
          {
            _id: checkoutToken,
            barcode: checkoutToken,
            error: BARCODE_SCAN_ERROR.INVALID_ACCOUNT,
          },
        ]);
        setAccounts([]);
        setCheckoutToken('');
      }
    },
    onError: (err) => {
      setInvalidItems([
        { _id: checkoutToken, barcode: checkoutToken, error: BARCODE_SCAN_ERROR.INVALID_PURCHASING_CODE },
      ]);
      setCheckoutToken('');
    },
  });

  const [submitOrder, { loading: submitLoading }] = useMutation(SUBMIT_ORDER_SELF_CHECKOUT, {
    client: ReadOnlyClient,
    onCompleted: () => {
      setActiveStepIndex(2);
    },
    onError: (err) => {
      showError(err?.message?.split(':').pop() || 'An unexpected error occurred. Please try again later.');
    },
  });

  useEffect(() => {
    setInvalidItems([]);
    if (activeStepIndex === 1) {
      setCheckoutToken('');
      setAccounts([]);
    }
  }, [activeStepIndex]);
  useEffect(() => {
    if (barcode && readOnlyItemData) {
      const { inventoryItemByBarcode, productBySku, inventoryItemWithoutBarcode } = readOnlyItemData;
      const itemWithoutBarcode =
        productBySku?.barcodeRequired === false
          ? { ...inventoryItemWithoutBarcode, barcode: productBySku.sku }
          : { product: productBySku, _id: productBySku._id, barcode: productBySku.sku };
      const item = inventoryItemByBarcode?._id ? inventoryItemByBarcode : itemWithoutBarcode;
      const { validItem, invalidItem } = checkForValidScannedItem(
        item,
        productBySku?._id ? item.product : null,
        barcode,
        data.store?.salesTax,
        items,
      );

      if (validItem) {
        setItems([...items, validItem]);
      } else {
        setInvalidItems([...new Set([...invalidItems, invalidItem])]);
      }

      setBarcode(null);
    }
  }, [barcode, data, items, invalidItems, readOnlyItemData]);

  if (loading) return <FuseLoading />;
  if (error) return <ErrorMessage />;

  const resetState = () => {
    setActiveStepIndex(0);
    setAccounts([]);
    setItems([]);
    setInvalidItems([]);
    setBarcode('');
    setCheckoutToken('');
    setOrderId(generateOrderId());
    idleTimer?.reset();
  };

  const clearIntervalCloseDialog = () => {
    setOpenIdleTimeoutDialog(false);
    clearInterval(interval.current);
  };

  const setIdleTimeOutCounter = () => {
    setOpenIdleTimeoutDialog(true);
    interval.current = setInterval(() => {
      setTimer((t) => {
        if (t === 1) {
          resetState();
          clearIntervalCloseDialog();
          return 30;
        }
        return t - 1;
      });
    }, 1000);
  };

  const { store } = data;
  if (!store.settings?.selfCheckout) {
    return <ErrorMessage message="Self checkout is not enabled on this store" />;
  }

  return (
    <FusePageSimple
      innerScroll
      sidebarInner
      classes={{
        contentWrapper: 'flex',
        content: 'flex-1 flex item-center overflow-x-auto ',
        header: 'min-h-128 h-128',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          icon={<Icon className="ml-16 mr-8 text-32 sm:w-48 sm:text-5xl">shopping_bag</Icon>}
          headerWithMetaData={
            <>
              <Typography className="text-16 sm:text-24">Self Checkout</Typography>
              <Typography variant="subtitle1">{store.name}</Typography>
            </>
          }
        />
      }
      content={
        <div className="flex-grow p-28">
          <DialogTemplate
            open={openIdleTimeoutDialog}
            // eslint-disable-next-line max-len
            content={`Due to inactivity, this page will refresh. If you wish to continue, please complete your order. This page will refresh in ${timer} seconds`}
            title="Self-Checkout Inactivity"
            primaryActions={
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    clearIntervalCloseDialog();
                    resetState();
                    setTimer(30);
                  }}>
                  Abandon Cart
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    clearIntervalCloseDialog();
                    setTimer(30);
                  }}>
                  Continue
                </Button>
              </>
            }
          />
          <DialogTemplate
            open={openErrorDialog}
            content="The fund is either invalid or has expired."
            title="Error"
            primaryActions={
              <Button variant="contained" color="primary" onClick={() => setErrorDialog(false)}>
                Ok
              </Button>
            }
          />
          <Stepper nonLinear activeStep={activeStepIndex} className="mx-auto mb-24 max-w-640">
            {SCAN_CHECKOUT_STEPS.map((label, index) => (
              <Step key={label}>
                <StepButton
                  disabled={index > activeStepIndex || activeStepIndex === 2}
                  onClick={() => setActiveStepIndex(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          {activeStepIndex !== 2 && (
            <div className="flex justify-between py-16">
              <Typography className="text-16">
                {activeStepIndex === 0
                  ? // eslint-disable-next-line max-len
                    `Scan items to purchase, then scan your Purchasing Code or ID (participating locations only) to complete the purchase.`
                  : `Scan your personal Purchasing Code or ID (participating locations only) to proceed with checkout.`}
              </Typography>
              {Boolean(items.length) && (
                <Button variant="contained" onClick={() => resetState()}>
                  Cancel Order
                </Button>
              )}
            </div>
          )}

          {activeStepIndex === 0 && (
            <ScanCheckoutItems
              store={store}
              items={items}
              invalidItems={invalidItems}
              onBarcodeScan={(scannedBarcode) => setBarcode(scannedBarcode)}
              onItemChange={(validItems) => setItems(validItems)}
              onInvalidItemChange={(updatedInvalidItems) => setInvalidItems(updatedInvalidItems)}
              onCheckout={() => setActiveStepIndex(1)}
              scanLoading={readOnlyItemLoading}
            />
          )}

          {activeStepIndex === 1 && (
            <PurchasingCodeCheckout
              storeId={store._id}
              accounts={accounts}
              items={items}
              onCodeScan={(scannedCode) => setCheckoutToken(scannedCode)}
              onScanErrorChange={(scannedErrors) => setInvalidItems(scannedErrors)}
              onSubmit={(accountDetails) =>
                submitOrder({
                  variables: {
                    token: checkoutToken,
                    storeId: store._id,
                    order: {
                      _id: orderId,
                      accountId: accountDetails.account._id,
                      fundId: accountDetails.fund.id,
                      deliveryLocationId: accountDetails.deliveryLocation.id,
                      receivingType: ORDER_RECEIVING.TYPES.SELF_CHECKOUT,
                      items: items.map((i) => ({
                        _id: i._id,
                        quantity: i.product.quantity ?? 1,
                        price: i.product.price,
                        productId: i.product._id,
                      })),
                      status: ORDER_STATUS.SELF_CHECKOUT,
                    },
                  },
                }).catch((e) => setErrorDialog(true))
              }
              scanErrors={invalidItems}
              scanLoading={tokenLoading}
              submitLoading={submitLoading}
            />
          )}

          {activeStepIndex === 2 && <CheckoutConfirmation onReset={() => resetState()} />}
        </div>
      }
    />
  );
};

export default SelfCheckoutPage;
