import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'unfetch/polyfill';
import 'focus-visible';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'typeface-roboto';

import App from './app/App';
import config from './app/config';
import * as serviceWorker from './serviceWorker';
import './styles/splash-screen.css';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';

(async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: config.LD_CLIENT_ID });

  // We only want to have logrocket track sessions in production.
  if (['hc', 'prod'].includes(config.ENV)) {
    LogRocket.init('rss/platform', {
      dom: {
        textSanitizer: false,
        inputSanitizer: false,
      },
    });
  }

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
