import { CircularProgress, Fade, Icon, IconButton, InputAdornment, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ORDER_RECEIVING } from '@risk-and-safety/library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ButtonProgress } from '../../../components';
import CartCheckout from '../CartCheckout';
import CartPrice from '../CartPrice';
import { canPlaceOrder } from '../../helper/cart.helper';
import { GET_USER_FROM_PURCHASING_CODE } from '../../graphql/query';
import { ErrorMessage } from '../../../components/errors';
import FuseLoading from '@fuse/core/FuseLoading';
import { ORDER_STATUS } from '@risk-and-safety/library';

import { BARCODE_SCAN_ERROR } from '../../helper/order.helper';

const INITIAL_CART_STATE = {
  account: null,
  fund: null,
  deliveryLocation: null,
  receivingType: ORDER_RECEIVING.TYPES.SELF_CHECKOUT,
};

const PurchasingCodeCheckout = ({
  accounts,
  items,
  onCodeScan,
  onScanErrorChange,
  onSubmit,
  scanErrors,
  scanLoading,
  submitLoading,
  storeId,
  purchaseType,
}) => {
  const [checkoutToken, setCheckoutToken] = useState('');
  const [checkoutTokenScan, setCheckoutTokenScan] = useState(false);
  const [shopper, setShopper] = useState('');
  const [cartDetails, setCartDetails] = useState(INITIAL_CART_STATE);
  const skip = !purchaseType || purchaseType !== ORDER_STATUS.IN_STORE || !checkoutToken || !checkoutTokenScan;
  const { error, loading } = useQuery(GET_USER_FROM_PURCHASING_CODE, {
    fetchPolicy: 'network-only',
    variables: { token: checkoutToken },
    skip,
    onCompleted: (data) => {
      setShopper(data?.userFromPurchasingCode);
    },
    onError: (err) => {
      onScanErrorChange([
        { _id: checkoutToken, barcode: checkoutToken, error: BARCODE_SCAN_ERROR.INVALID_PURCHASING_CODE },
      ]);
      setCheckoutToken('');
      setCheckoutTokenScan(false);
    },
  });

  if (loading) return <FuseLoading />;
  if (error) return <ErrorMessage message={error.message} />;

  return (
    <>
      <TextField
        id="purchasing-code"
        name="Purchasing Code"
        placeholder="Scan Purchasing Code or ID"
        autoFocus
        autoComplete="off"
        value={checkoutToken}
        onKeyPress={({ key, target: { value } }) => {
          if (key === 'Enter' && value.length) {
            setCheckoutTokenScan(true);
            onCodeScan(value);
          }
        }}
        onChange={({ target: { value } }) => {
          if (!scanLoading) {
            setCheckoutTokenScan(false);
            setCheckoutToken(value);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon className="text-gray-300">qr_code_scanner</Icon>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {scanLoading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => {
                    onCodeScan('');
                    setCheckoutToken('');
                  }}>
                  <Icon>close</Icon>
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        className="mb-16 mt-8"
        variant="outlined"
        fullWidth
      />

      <div className="mb-12">
        {scanErrors.map((invalidItem) => (
          <Fade in key={invalidItem._id}>
            <Alert
              severity="error"
              onClose={() => onScanErrorChange(scanErrors.filter((i) => i._id !== invalidItem._id))}
              className="mb-2">
              {invalidItem.error} - {invalidItem.barcode}
            </Alert>
          </Fade>
        ))}
      </div>

      <div className="flex flex-wrap">
        <div className="mr-8 flex-grow">
          <CartCheckout
            checkoutByPurchasingCode
            storeId={storeId}
            accounts={accounts}
            cart={cartDetails}
            purchaseType={purchaseType}
            onUpdate={(cart, selectedShopper) => {
              setCartDetails({ ...cartDetails, ...cart });
              if (selectedShopper) setShopper(selectedShopper);
            }}
          />
        </div>
        <div className="ml-8 min-w-256 flex-none">
          <CartPrice storeId={storeId} items={items} />
          <ButtonProgress
            loading={submitLoading}
            variant="contained"
            color="primary"
            className="w-full"
            disabled={
              !canPlaceOrder(
                { ...cartDetails, items },
                { ...cartDetails, isFundValid: true },
                purchaseType === ORDER_STATUS.IN_STORE,
                shopper,
              )
            }
            onClick={() => onSubmit(cartDetails, shopper)}>
            Place Order
          </ButtonProgress>
        </div>
      </div>
    </>
  );
};

PurchasingCodeCheckout.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCodeScan: PropTypes.func.isRequired,
  onScanErrorChange: PropTypes.func.isRequired,
  scanErrors: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  scanLoading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  storeId: PropTypes.string.isRequired,
};

PurchasingCodeCheckout.defaultProps = {
  accounts: [],
  scanErrors: [],
};

export default PurchasingCodeCheckout;
