import { useMutation, useQuery } from '@apollo/client';
import FusePageSimple from '@fuse/core//FusePageSimple';
import {
  Button,
  Icon,
  List,
  ListSubheader,
  Paper,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from '@material-ui/core';
import { ORDER_RECEIVING, ORDER_STATUS, Store } from '@risk-and-safety/library';
import { PersonReference } from '@rss/common';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useToast } from '../../hooks';
import { Header } from '../../components';
import { CheckoutConfirmation, PurchasingCodeCheckout, ScanCheckoutItems } from '../components';
import {
  BARCODE_SCAN_ERROR,
  generateOrderId,
  SCAN_CHECKOUT_STEPS,
  checkForValidScannedItem,
} from '../helper/order.helper';
import { ACCOUNTS_BY_STORE, ITEM_BY_BARCODE_OR_SKU, SUBMIT_ORDER_STORE_CHECKOUT } from '../graphql/query';
import { ACCOUNTS_BY_USER } from '../graphql/public-query';
import { ReadOnlyClient } from '../../services/apollo';

function StoreCheckoutPage({ store }) {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [items, setItems] = useState([]);
  const [invalidItems, setInvalidItems] = useState([]);
  const [barcode, setBarcode] = useState(null);
  const [checkoutToken, setCheckoutToken] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [orderId, setOrderId] = useState();
  const { showError } = useToast();
  useEffect(() => setOrderId(generateOrderId()), []);
  const [notes, setNotes] = useState('');
  const { data: itemData, loading: itemLoading } = useQuery(ITEM_BY_BARCODE_OR_SKU, {
    variables: { barcode, inventoryId: store.inventoryId, storeId: store._id },
    skip: !barcode,
    fetchPolicy: 'no-cache',
    onError: (err) => {
      setInvalidItems([...new Set([...invalidItems, { _id: barcode, barcode, error: BARCODE_SCAN_ERROR.NOT_FOUND }])]);
    },
  });

  const { loading: accountsLoading } = useQuery(ACCOUNTS_BY_STORE, {
    variables: { storeId: store._id, administeredByLabel: 'INVENTORY' },
    fetchPolicy: 'network-only',
    skip: !!checkoutToken,
    onCompleted: ({ findAccountsForStore = [] }) => {
      if (findAccountsForStore.some((a) => a.funds?.length && a.deliveryLocations?.length)) {
        setInvalidItems([]);
        setAccounts(findAccountsForStore.map((ac) => ({ ...ac, name: ac.administeredBy.name })));
      } else {
        setInvalidItems([
          {
            _id: checkoutToken,
            barcode: checkoutToken,
            error: BARCODE_SCAN_ERROR.INVALID_ACCOUNT,
          },
        ]);
        setAccounts([]);
        setCheckoutToken('');
      }
    },
    onError: (err) => {
      setInvalidItems([
        { _id: checkoutToken, barcode: checkoutToken, error: BARCODE_SCAN_ERROR.INVALID_PURCHASING_CODE },
      ]);
      setCheckoutToken('');
    },
  });

  const { loading: tokenLoading, refetch } = useQuery(ACCOUNTS_BY_USER, {
    variables: { storeId: store._id, administeredByLabel: 'INVENTORY', token: checkoutToken },
    client: ReadOnlyClient,
    fetchPolicy: 'network-only',
    skip: !checkoutToken,
    onCompleted: ({ findAccountsForUser = [] }) => {
      if (findAccountsForUser.some((a) => a.funds?.length && a.deliveryLocations?.length)) {
        setInvalidItems([]);
        setAccounts(findAccountsForUser.map((ac) => ({ ...ac, name: ac.administeredBy.name })));
      } else {
        setInvalidItems([
          {
            _id: checkoutToken,
            barcode: checkoutToken,
            error: BARCODE_SCAN_ERROR.INVALID_ACCOUNT,
          },
        ]);
        setAccounts([]);
        setCheckoutToken('');
      }
    },
    onError: (err) => {
      setInvalidItems([
        { _id: checkoutToken, barcode: checkoutToken, error: BARCODE_SCAN_ERROR.INVALID_PURCHASING_CODE },
      ]);
      setCheckoutToken('');
    },
  });

  const [submitOrder, { loading: submitLoading }] = useMutation(SUBMIT_ORDER_STORE_CHECKOUT, {
    onCompleted: () => {
      setActiveStepIndex(2);
    },
    onError: (err) => {
      showError(err?.message?.split(':').pop() || 'An unexpected error occurred. Please try again later.');
    },
  });

  useEffect(() => {
    setInvalidItems([]);
    if (activeStepIndex === 1) {
      setCheckoutToken('');
      refetch();
    }
  }, [activeStepIndex]);

  useEffect(() => {
    if (barcode && itemData) {
      const { validItem, invalidItem } = checkForValidScannedItem(
        itemData.inventoryItemByBarcode,
        itemData.productBySku,
        barcode,
        store?.salesTax,
        items,
      );

      if (validItem) {
        setItems([...items, validItem]);
      } else {
        setInvalidItems([...new Set([...invalidItems, invalidItem])]);
      }

      setBarcode(null);
    }
  }, [barcode, itemData, items, invalidItems, store]);

  const resetState = () => {
    setActiveStepIndex(0);
    setAccounts([]);
    setItems([]);
    setInvalidItems([]);
    setBarcode('');
    setCheckoutToken('');
  };

  return (
    <FusePageSimple
      classes={{
        content: 'relative',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
        contentWrapper: 'overflow-hidden',
        toolbar: 'bg-gray-50 min-h-36 h-36 py-2 flex border-b-1',
      }}
      header={
        <div className="flex flex-grow px-32">
          <Header
            icon={<Icon className="text-32 sm:w-48 sm:text-5xl">shopping_bag</Icon>}
            headerWithMetaData={
              <>
                <Typography className="text-16 sm:text-24">Store Checkout</Typography>
                <Typography variant="subtitle1">{store.name}</Typography>
              </>
            }
            backArrow={{
              link: `../${store._id}`,
              name: 'Store',
            }}
          />
        </div>
      }
      content={
        <div className="flex-grow p-28">
          <Stepper nonLinear activeStep={activeStepIndex} className="mx-auto mb-24 max-w-640">
            {SCAN_CHECKOUT_STEPS.map((label, index) => (
              <Step key={label}>
                <StepButton
                  disabled={index > activeStepIndex || activeStepIndex === 2}
                  onClick={() => setActiveStepIndex(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          {activeStepIndex !== 2 && (
            <div className="flex justify-between py-16">
              <Typography className="text-16">
                {activeStepIndex === 0
                  ? // eslint-disable-next-line max-len
                    `Scan items or product sku, then scan the customer's Purchasing Code to complete their purchase.`
                  : `Scan the customer's Purchasing Code to proceed with checkout.`}
              </Typography>
              {Boolean(items.length) && (
                <Button variant="contained" onClick={() => resetState()}>
                  Cancel Order
                </Button>
              )}
            </div>
          )}

          {activeStepIndex === 0 && (
            <ScanCheckoutItems
              store={store}
              items={items}
              invalidItems={invalidItems}
              onBarcodeScan={(scannedBarcode) => setBarcode(scannedBarcode)}
              onItemChange={(validItems) => setItems(validItems)}
              onInvalidItemChange={(updatedInvalidItems) => setInvalidItems(updatedInvalidItems)}
              onCheckout={() => setActiveStepIndex(1)}
              scanLoading={itemLoading}
            />
          )}

          {activeStepIndex === 1 && (
            <>
              <PurchasingCodeCheckout
                storeId={store._id}
                accounts={accounts}
                items={items}
                purchaseType={ORDER_STATUS.IN_STORE}
                onCodeScan={(scannedCode) => setCheckoutToken(scannedCode)}
                onScanErrorChange={(scannedErrors) => setInvalidItems(scannedErrors)}
                onSubmit={(accountDetails, shopper) =>
                  submitOrder({
                    variables: {
                      token: checkoutToken,
                      shopper: shopper ? new PersonReference(shopper) : '',
                      storeId: store._id,
                      order: {
                        _id: orderId,
                        accountId: accountDetails.account._id,
                        fundId: accountDetails.fund.id,
                        deliveryLocationId: accountDetails.deliveryLocation.id,
                        receivingType: ORDER_RECEIVING.TYPES.PICKUP,
                        items: items.map((i) => ({
                          _id: i._id,
                          quantity: i.product.quantity ?? 1,
                          price: i.product.price,
                          productId: i.product._id,
                        })),
                        status: ORDER_STATUS.IN_STORE,
                      },
                      notes: notes,
                    },
                  })
                }
                scanErrors={invalidItems}
                scanLoading={tokenLoading || accountsLoading}
                submitLoading={submitLoading}
              />
              <Paper className="mr-8 mt-16 w-1/3 rounded-8 pb-16 shadow-1">
                <List subheader={<ListSubheader component="div">Notes</ListSubheader>}>
                  <div id="notes" className="mx-12">
                    <label>
                      <TextField
                        id="notes"
                        aria-label="notes"
                        value={notes || ''}
                        inputProps={{ maxLength: 50 }}
                        className="w-full"
                        onChange={({ target: { value } }) => setNotes(value)}
                        helperText={`${notes.length} / 50 characters`}
                      />
                    </label>
                  </div>
                </List>
              </Paper>
            </>
          )}

          {activeStepIndex === 2 && <CheckoutConfirmation onReset={() => resetState()} />}
        </div>
      }
    />
  );
}

StoreCheckoutPage.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default StoreCheckoutPage;
