import { useQuery } from '@apollo/client';
import {
  FormControl,
  FormControlLabel,
  List,
  ListSubheader,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
  InputLabel,
  Box,
} from '@material-ui/core';
import { ORDER_RECEIVING } from '@risk-and-safety/library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { formatFundValueToString } from '../helper/account.helper';
import { STORE_ACCOUNT } from '../graphql/query';
import { Search } from '../../components';
import { ORDER_STATUS } from '@risk-and-safety/library';
function CartCheckout({
  accounts,
  storeId,
  cart,
  checkoutByPurchasingCode,
  onUpdate,
  allowDelivery,
  allowPickup,
  purchaseType,
}) {
  const [searchAccounts, setSearchAccounts] = useState(accounts || []);
  const [searchUsers, setSearchUsers] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState();
  const [shopper, setShopper] = useState();
  const { data } = useQuery(STORE_ACCOUNT, {
    variables: { id: cart.account?._id || accounts[0]?._id },
    skip: checkoutByPurchasingCode,
  });
  const account = checkoutByPurchasingCode ? cart.account || accounts[0] : data?.storeAccount;
  const members = account?.administeredBy?.members || account?.members || [];

  useEffect(() => {
    setSearchAccounts(accounts);
  }, [accounts]);

  useEffect(() => {
    if (!cart.account && account && purchaseType !== ORDER_STATUS.IN_STORE) {
      onUpdate({
        account,
        deliveryLocation: account.deliveryLocations.find((loc) => loc.default) || {},
        fund: account.funds.find((f) => f.default) || {},
      });
    }
    setSearchUsers(account?.administeredBy?.members || account?.members || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.account, account]);

  const accountSelect = (acc) => {
    onUpdate({
      storeId,
      account: acc,
      fund: {},
      deliveryLocation: (acc?.deliveryLocations || []).find((loc) => loc.default),
    });

    if (acc._id !== selectedAcc) {
      setSelectedAcc(acc._id);
    }
  };

  const filterOnChange = ({ inputValue }) => {
    setSearchAccounts(accounts.filter((acc) => acc?.name?.toLowerCase().includes(inputValue?.toLowerCase())));
    setShopper(null);
  };

  return (
    <>
      <Paper className="mb-16 rounded-8 pb-16 shadow-1">
        <List subheader={<ListSubheader component="div">Payment Method</ListSubheader>}>
          {Boolean(accounts?.length) && (
            <div className="p-20 pb-12">
              <Typography color="textSecondary" className="w-1/2 pb-8 font-medium">
                Account
              </Typography>

              {checkoutByPurchasingCode && purchaseType === ORDER_STATUS.IN_STORE && accounts.some((account) => account.administeredBy?.members?.length) ? (
                <div className="mb-16">
                  <FormControl variant="outlined" className="mt-10 w-1/2">
                    <Search
                      id="Account-Search"
                      label="Search accounts"
                      itemToString={(acc) => acc.name}
                      onSelect={accountSelect}
                      onChange={filterOnChange}
                      openMenuOnFocus={false}
                      autocomplete={true}
                      disabled={false}
                      minSearchLength={0}
                      items={searchAccounts.map((item) => ({ ...item, key: item._id }))}
                      renderItem={(acc) => (
                        <Typography variant="body2" align="left">
                          <span className="mr-2">{acc.name}</span>
                        </Typography>
                      )}
                    />
                  </FormControl>

                  <FormControl variant="outlined" className="mt-10 w-1/2 pl-12">
                    <Search
                      key={selectedAcc}
                      id="User-Search"
                      label="Search users"
                      itemToString={(user) => `${user?.firstName} ${user?.lastName}`}
                      onSelect={(user) => {
                        onUpdate(
                          {
                            storeId,
                            account,
                            fund: {},
                            deliveryLocation: (account?.deliveryLocations || []).find((loc) => loc.default),
                          },
                          user,
                        );
                        setShopper(user);
                      }}
                      onChange={({ inputValue }) => {
                        const filteredUsers = members?.filter((user) => {
                          const name = `${user.firstName} ${user.lastName}`;
                          return name.toLowerCase().includes(inputValue.toLowerCase());
                        });
                        setSearchUsers(filteredUsers);
                      }}
                      openMenuOnFocus={false}
                      autocomplete={true}
                      disabled={false}
                      minSearchLength={0}
                      items={searchUsers.map((item) => ({ ...item, key: item.userId }))}
                      renderItem={(user) => (
                        <Typography variant="body2" align="left">
                          <span className="mr-2">{`${user.firstName} ${user.lastName}`}</span>
                        </Typography>
                      )}
                    />
                  </FormControl>
                </div>
              ) : (
                <FormControl variant="outlined" className="w-1/2 pr-12">
                  <Select
                    value={account?._id || ''}
                    onChange={({ target: { value } }) => {
                      const acc = accounts.find((a) => a._id === value);
                      onUpdate({
                        storeId,
                        account: acc,
                        fund: {},
                        deliveryLocation: (acc?.deliveryLocations || []).find((loc) => loc.default),
                      });
                    }}>
                    {accounts.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {account && (
                <FormControl variant="outlined" className="w-1/2">
                  <InputLabel>Funds</InputLabel>
                  <Select
                    label="Funds"
                    disabled={account.funds?.length === 0}
                    value={cart.fund?.id || (account.funds || []).map((f) => f.default)?.id || ''}
                    onChange={({ target: { value } }) => {
                      onUpdate({ storeId, fund: account.funds?.find((f) => f.id === value) }, shopper);
                    }}
                    inputProps={{ 'aria-label': 'fund' }}>
                    {account.funds?.map((fund) => (
                      <MenuItem key={fund.id} value={fund.id}>
                        {fund.name || formatFundValueToString(fund.value)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {!account?.funds?.length && !checkoutByPurchasingCode && (
                <Link
                  to={{ pathname: `/shop/${storeId}/account/${account?._id}`, state: { fromCart: true } }}
                  style={{ textDecoration: 'none' }}>
                  <Typography className="pt-12 text-16">
                    Add a fund to this account before placing your order.
                  </Typography>
                </Link>
              )}
            </div>
          )}
        </List>
      </Paper>

      <Paper className="rounded-8 pb-16 shadow-1">
        <List subheader={<ListSubheader component="div">Receiving Method</ListSubheader>}>
          {checkoutByPurchasingCode ? (
            <Typography variant="body1" className="p-16">
              This is an in-store purchase.
            </Typography>
          ) : (
            <RadioGroup
              name="receiving"
              value={cart?.receivingType}
              onChange={({ target: { value } }) => onUpdate({ storeId, receivingType: value }, shopper)}
              className="p-16"
              row>
              {ORDER_RECEIVING.OPTIONS.filter(
                (option) =>
                  (option.value === ORDER_RECEIVING.TYPES.DELIVERY && allowDelivery) ||
                  (option.value === ORDER_RECEIVING.TYPES.PICKUP && allowPickup),
              ).map(({ label, value }) => (
                <FormControlLabel key={value} label={label} value={value} control={<Radio />} />
              ))}
            </RadioGroup>
          )}
          {cart.receivingType && (
            <div className="px-20">
              {Boolean(account?.deliveryLocations?.length) && (
                <>
                  <Typography color="textSecondary" className="w-1/2 pb-8 font-medium">
                    {cart.receivingType === 'DELIVERY' ? 'Delivery Location' : 'Transfer Location'}
                  </Typography>
                  <FormControl variant="outlined" className="w-full">
                    <Select
                      value={cart.deliveryLocation?.id || ''}
                      onChange={({ target: { value } }) => {
                        onUpdate(
                          {
                            storeId,
                            deliveryLocation: account.deliveryLocations?.find((l) => l.id === value),
                          },
                          shopper,
                        );
                      }}
                      className="h-44"
                      inputProps={{ 'aria-label': 'location' }}>
                      {account.deliveryLocations.map((location) => (
                        <MenuItem key={location.id} value={location.id}>
                          {location.buildingName} Room {location.roomNumber}
                          {location.sublocationId ? ` , ${location.sublocationName}` : ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              {!checkoutByPurchasingCode && !account?.deliveryLocations?.length && (
                <Link
                  to={{ pathname: `/shop/${storeId}/account/${account?._id}`, state: { fromCart: true } }}
                  style={{ textDecoration: 'none' }}>
                  <Typography className="text-16">Add a location to this account before placing your order.</Typography>
                </Link>
              )}
            </div>
          )}
        </List>
      </Paper>
    </>
  );
}

CartCheckout.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  storeId: PropTypes.string.isRequired,
  cart: PropTypes.shape({
    account: PropTypes.objectOf(PropTypes.any),
    fund: PropTypes.objectOf(PropTypes.any),
    deliveryLocation: PropTypes.objectOf(PropTypes.any),
    receivingType: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  checkoutByPurchasingCode: PropTypes.bool,
  allowDelivery: PropTypes.bool,
  allowPickup: PropTypes.bool,
};

CartCheckout.defaultProps = {
  checkoutByPurchasingCode: false,
  allowDelivery: false,
  allowPickup: false,
};

export default CartCheckout;
